import { Outlet, Link, useLocation } from "react-router-dom";
import styles from "./Layout.module.css";
import TopNavLogo from "../../assets/iiba-logo-485x202.png";
import TopNavKHLogo from "../../assets/kh-logo-150x30.png";
import { CopyRegular, ShareRegular, ThumbDislike20Filled, ThumbLike20Filled } from "@fluentui/react-icons";
import { Dialog, Stack, TextField, DefaultButton, PrimaryButton, ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { HistoryButton, ShareButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { CosmosDBStatus } from "../../api";

const Layout = () => {
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState('');
    const [feedbackText, setFeedbackText] = useState('');
    const [feedbackDetail, setFeedbackDetail] = useState('');
    const [isSharePanelOpen, setIsSharePanelOpen] = useState(false);
    const [copyClicked, setCopyClicked] = useState(false);
    const [copyText, setCopyText] = useState("Copy URL");
    const [shareLabel, setShareLabel] = useState<string | undefined>("Share");
    const [hideHistoryLabel, setHideHistoryLabel] = useState<string>("Hide chat history");
    const [showHistoryLabel, setShowHistoryLabel] = useState<string>("Show chat history");
    const appStateContext = useContext(AppStateContext);
    const ui = appStateContext?.state.frontendSettings?.ui;
    const negativeFeedbackOptions: IChoiceGroupOption[] = [
        { key: 'Content was incorrect', text: 'Content was incorrect.' },
        { key: 'Content wasn\'t useful', text: 'Content wasn\'t useful.' },
        { key: 'It was hard to use', text: 'It was hard to use.' },
        { key: 'Other', text: 'Other', },
    ];

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' });
    };

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 480) {
            setShareLabel(undefined)
            setHideHistoryLabel("Hide history")
            setShowHistoryLabel("Show history")
          } else {
            setShareLabel("Share")
            setHideHistoryLabel("Hide chat history")
            setShowHistoryLabel("Show chat history")
          }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const submitFeedback = async () => {
        if (!feedbackType || !feedbackText) {
            alert('Please fill in all required fields.');
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const feedbackUsername = queryParams.get('username');

        const feedbackData = {
            type: feedbackType,
            text: feedbackText,
            detail: feedbackDetail, 
            dateOfSubmission: new Date().toISOString(),
            username: feedbackUsername,
        };

        const webhookUrl = 'https://hooks.zapier.com/hooks/catch/12197086/3ldi4qf';

        try {
            const response = await fetch(webhookUrl, {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(feedbackData),
            });

                alert('Feedback submitted successfully.');
                setIsFeedbackDialogOpen(false);
                setFeedbackType('');
                setFeedbackText('');
                setFeedbackDetail('');
            
        } catch (error) {
            console.error('Error submitting feedback:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center">
                    <a href="https://www.iiba.org" target="_blank"><img
                            src={TopNavLogo}
                            className={styles.headerIconTopNav}
                            aria-hidden="true"
                        /></a>
                        <Link to="/" className={styles.headerTitleContainer}>
                        <h1 className={styles.headerTitle}>AI Assistant</h1>
                        </Link>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 4 }}>
                        {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                            <HistoryButton onClick={handleHistoryClick} text={appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel} />
                        }
                            {/* <ShareButton onClick={handleShareClick} text={shareLabel} /> */}  
                        {/* <DefaultButton iconProps={{iconName: 'Feedback'}} text="Give Feedback" onClick={() => setIsFeedbackDialogOpen(true)} /> */}
                        <DefaultButton id="likebtn" iconProps={{ iconName: 'Like' }} onClick={() => { setIsFeedbackDialogOpen(true); setFeedbackType('positive'); }} />
                        <DefaultButton id="dislikebtn" iconProps={{ iconName: 'Dislike' }} onClick={() =>{ setIsFeedbackDialogOpen(true); setFeedbackType('negative'); }} />

                        <a href="https://www.iiba.org/knowledgehub/" target="_blank" rel="noopener noreferrer"><img
                            src={TopNavKHLogo}
                            className={styles.headerIconKH}
                            aria-hidden="true"
                        /></a>
                    </Stack>

                </Stack>
            </header>
            <Outlet />
            <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Share the web app",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog>
            <Dialog
                hidden={!isFeedbackDialogOpen}
                onDismiss={() => { setIsFeedbackDialogOpen(false); setFeedbackType(''); }}
                dialogContentProps={{ title: 'Feedback' }}
                maxWidth={450}
            >
                <Stack tokens={{ childrenGap: 20 }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="center">
                        <ThumbLike20Filled style={{ cursor: 'pointer', fontSize: 24 }} onClick={() => setFeedbackType('positive')} />
                        <ThumbDislike20Filled style={{ cursor: 'pointer', fontSize: 24 }} onClick={() => setFeedbackType('negative')} />
                    </Stack>
                    {feedbackType === 'positive' && (
                        <TextField
                            label="What did you like?"
                            multiline
                            rows={3}
                            value={feedbackText}
                            onChange={(e, newValue) => setFeedbackText(newValue || '')}
                        />
                    )}
                    {feedbackType === 'negative' && (
                        <>
                            <ChoiceGroup
                                options={negativeFeedbackOptions}
                                label="What didn't you like?"
                                onChange={(e, option) => option && setFeedbackText(option.key)}
                            />
                            <TextField
                                label="Please tell us what happened:"
                                multiline
                                rows={3}
                                onChange={(e, newValue) => setFeedbackDetail(newValue || '')}
                            />
                        </>
                    )}
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <DefaultButton text="Cancel" onClick={() => setIsFeedbackDialogOpen(false)} />
                        <PrimaryButton text="Submit" onClick={submitFeedback} />
                    </Stack>
                </Stack>
            </Dialog>
            <footer className={styles.footer}>
                <div>This feature relies on AI, and at times it can make mistakes. Avoid sharing personal or confidential information.</div> 
                <a href="https://www.iiba.org/knowledgehub/the-power-of-ai-in-knowledgehub-for-business-analysis/" target="_blank">FAQ </a> | <a href="https://www.iiba.org/footer/policies/iiba-privacy-policy" target="_blank">Privacy Policy</a> | <a href="https://www.iiba.org/footer/terms-of-use/" target="_blank">Terms of Use</a>
            </footer>
        </div>
    );
};

export default Layout;
